// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses environment.ts, but if you do
// ng build --env=prod then environment.prod.ts will be used instead.
// The list of which env maps to which file can be found in .angular-cli.json.


export const environment = {
    production: true,
    isFirebaseEnabled: true,
    prodType: 'dev',
    appUrl: "https://stagingapi.vanisoftware.com/",
    firebase: {
        apiKey: 'AIzaSyBQv4XBZ46x_gTWAwpHoYN8EHFXIMRQtTw',
        authDomain: 'vani-dev.firebaseapp.com',
        databaseURL: 'https://vani-dev.firebaseio.com',
        projectId: 'vani-dev',
        storageBucket: 'vani-dev.appspot.com',
        messagingSenderId: '41300047286',
        appId: "1:41300047286:web:c0bd4d7108ca0e2a4c3d17"
    },
    firebaseList: 'vani-dev',
    pusher: {
      debug: true,
      key: 'vani-staging',
      cluster: 'mt1',
      wsHost: 'stagingapi.vanisoftware.com',
      wsPort: 6002,
      wssPort: 6002,
      transports: ['ws','wss'],
    },
};
