import { Injectable } from '@angular/core';
import { ThumbnailModalComponent } from '../components/shared/imageThumbnail/thumbnail-modal/thumbnail-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ThumbnailModalServiceService {

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService,) {

  }

  /** Open Progress modal */
  openThumbnailModal(imgUrl:string) {
    this.bsModalRef = this.modalService.show(ThumbnailModalComponent, {
      backdrop: true,
      keyboard: false,
      class: 'modal-dialog-centered',
      initialState : imgUrl
    });
  }

  closeThumbnailModal(){
    this.bsModalRef.hide();
  }


}
