import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from '../../../common/common-js';
import { DataService } from '../../../services/data.service';
import { AppConfigs } from '../../../common/app-configs';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from '../../../services/toastr.service';
import { WINDOW } from '../../../services/window.service';
import { DOCUMENT } from '@angular/common';
import Pusher from 'pusher-js/worker';
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/services/pusher.service';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  host: {
    "(window:click)": "onClickAnywhereElse()"
  }
})
export class DashboardComponent implements OnInit {

  isClassVisible = false;
  entityName = ' Project Dashboard';
  is_open_detail = false;
  data: { [index: string]: any } = {};
  projectId = '';
  projects: any;
  getprojects: any;
  taskProgressCount: any;
  approvedCount: any;
  activeData: any;
  assetShot: any;
  currentProject: string;
  clientCodePrefix = "";
  projectData: { [index: string]: any } = {};
  projectroles = {};
  projectrolestype = {};
  settings: any = JSON.parse(localStorage.getItem('settings'));
  uploadUrl = this.commonJs.getUploadUrl();
  parentProjectId: any;
  //Task Progress
  public taskProgressLabels: string[] = ['Completed', 'Pending'];
  public taskProgressTypeChart: string = 'doughnut';
  public taskProgressData: number[] = [];
  public projectEfficiency: number[] = [];
  public taskProgressOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    circumference: Math.PI,
    rotation: 1.0 * Math.PI,
    //cutoutPercentage: 70,
    legend: {
      position: 'right',
      labels: {
        fontFamily: "Nunito",
        fontSize: 10
      },
      layout: {
        padding: 40
      },
    },

  };
  public taskProgressLegend: boolean = false;
  public taskProgressColors: any[] = [{ backgroundColor: ["#58be36", "#eee"] }];

  //Bid-Actual Days
  bidDays: any;

  //Phase Approved tasks
  phaseList: any = [];
  departments: { [index: string]: any } = {};

  //Tasks Status
  tasksStatus: { [index: string]: any } = {};
  role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';
  //role:string;
  roleType: string;

  //EmpType Pie
  public taskStatusLabels = [];
  public taskStatusLegend: boolean = false;
  public taskStatusTypePie: string = 'pie';
  public taskStatusDataPie: number[] = [0, 0, 0, 0];
  public taskStatusOptionsPie: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        fontFamily: "Nunito",
        fontSize: 10
      },
    },
  };
  public taskStatusColors: any[] = [
    {
      backgroundColor: []
    }];
  isTaskStatus: boolean = false;
  isRetakeType: boolean = false;

  //
  where: { [index: string]: any } = {};
  deptList: { [index: string]: any } = {};
  episodesList: { [index: string]: any } = {};
  departmentTasks: { [index: string]: any } = {};

  rtEpisodeList: { [index: string]: any } = {};
  rtdeptList: { [index: string]: any } = {};

  //getMonthlyTasksProgress
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;



  //Non workable Inventory
  nonWorkableInventory: number = 0;



  submittedDataSet: any[] = [];
  approvedDataSet: any[] = [];

  clientCode = localStorage.getItem('client_code');
  isProjectDashboardView: boolean = false;

  taskProgress = [];
  projectCompletion: boolean = true;
  actualVsBidDays: boolean = true;
  teamMeberAndShotAsset: boolean = true;
  shotStatusGraph: boolean = true;
  assetStatusGraph: boolean = true;
  retakeTypes = [];
  totalRetake: number = 0;
  isRetakeStatus: boolean = true;

  //retake types chart
  public retakeTypeLabels = [];
  public retakeTypeLegend: boolean = false;
  public retakeTypePie: string = 'pie';
  public retakeTypeDataPie: number[] = [0, 0, 0, 0];
  public retakeTypeOptionsPie: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        fontFamily: "Nunito",
        fontSize: 10
      },
    }
  };
  public retakeTypeColors: any[] = [
    {
      //backgroundColor:["#A9A9A9","#87CEFA","#1a66ef","#FF9800","#a5d6a8","#a5d6a8","#A569BD","#2b982b","#ff6666","#bbb","#FF8C00","#bbb","#ff6666"]
      backgroundColor: []
    }];

  retakeTypeList = [];



  /** Shot Assetss status report data */

  public barChartOptions: any = {
    responsive: true,
    barRoundness: 0.2,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 20,
        }
      }
    },
    tooltips: {
      yAlign: null,
      xAlign: 'center',
      callbacks: {
        label: (tooltipItem, data) => {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          label += JSON.stringify(tooltipItem.xLabel) + ' %';
          return label;
        }
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
          color: "rgba(0, 0, 0, 0)",
        },
        ticks: {
          display: false,
          beginAtZero: true,
        }
      }],
      yAxes: [
        {
          gridLines: {
            display: false,
            color: "rgba(0, 0, 0, 0)",
          }
        }
      ]
    }
  };

  public barChartColors: any = [
    {
      backgroundColor: ['rgb(169, 169, 169)', 'rgb(43, 152, 43)', 'rgb(26, 102, 239)', '#F26927']
    }
  ]

  public barChartType: any = 'horizontalBar';
  public barChartLegend = false;

  public barChartData: any;
  public barChartLabels: Array<any> = [];


  public barChartDataAssets: any;
  public barChartLabelsAssets: Array<any> = [];
  isViewPermission = [];
  visiblePhases = [];
  phasesExist = {};

  constructor(
    public router: Router,
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public appConfigs: AppConfigs,
    private dataService: DataService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private pusherService: PusherService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window) {
  }

  ngOnInit() {

    if (localStorage.getItem('phasesExist')) {
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    } else if (this.commonJs.phasesExist) {
      this.phasesExist = this.commonJs.phasesExist;
    } else {
      this.phasesExist = { pre: 1, character: 1, set: 1, prop: 1, shot: 1, post: 1 };
    }

    this.titleService.setTitle(this.settings.html_prefix + this.entityName);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      this.dataService.projectIdByDashboard = this.projectId;
      this.currentProject = this.projectId;
      this.getProjectData();
      const types = ['taskProgress', 'bidDaysProgress', 'activeDataAssetShot'];
      types.forEach(type => this.getProjectProgress(type));
      this.shotStatusReport(this.projectId);
      this.assetStatusReport(this.projectId);
      this.retakeStatusToggled('');
      this.statusTabToggled('');
      // Retrieve filter values from local storage or use default values if not found
      this.data.rt_department_id = { id: 0, department: 'All', dtt_id: null };
      this.data.rt_episode_id = { id: 0, name: 'All' };
      this.data.retake_type_id = { id: 'all', item_name: 'All' };

      //Project Dashboard View
      this.isProjectDashboardView = this.commonJs.checkAccess('project_dashboard', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['projects'] = this.commonJs.checkAccess('projects', 'view', { 'baiPass': ['admin'] });

      if (this.role != 'vendor') {
        //this.getTasksProgress();
        if (this.role != 'client') {
          //this.getBidDaysProgress();
        }
        //this.getActiveData();
        //this.getAssetShotProgress();
        this.getDepartmentTasks();
        this.getPhaseByType(this.projectId, '', true);
        this.clientCodePrefix = this.commonJs.getClientCodePrefix();
        //this.getMonthlyTasksProgress();
      }
    });


    this.taskProgressOptions['pieceLabel'] = {
      fontColor: '#000',
      fontSize: 10,
      fontFamily: 'Nunito',
      render: function (args) {
        return args.value + '%';
      }
    };

    /** Pusher js live update data without reload page */
    if (environment.production) {

      const channel = this.pusherService.subscribeToChannel(`tasks`);
      // const channel = this.pusher.subscribe(`${'tasks.'}${this.projectId}`);
      channel.bind(`tasks`, (data) => {
        if(data.projectId == this.projectId){
          this.getProjectData();
          this.statusPreferance();
          const types = ['taskProgress', 'bidDaysProgress', 'activeDataAssetShot'];
          types.forEach(type => this.getProjectProgress(type));
          this.shotStatusReport(this.projectId);
          this.assetStatusReport(this.projectId);
          this.cd.markForCheck();
        }
      });
    }
    /** *************************************************** END ***************************************************/
  }

  ngOnDestroy() {
    this.pusherService.unsubscribeFromChannel(`tasks`);
  }

  /** Get project status preferance */
  statusPreferance() {
    this.dataService.getListData('getTasksStatus', { 'project_id': this.projectId, 'role': this.role, 'type': 2, 'where': this.where })
      .subscribe((success) => {
        this.tasksStatus = success.data;
        this.taskStatusDataPie = success.data.statusChartData;
        this.taskStatusLabels = success.data.statusNames;
        this.role = success.data.role;
        this.roleType = success.data.roleType;
        this.taskStatusColors[0]['backgroundColor'] = success.data.chartColor;
        this.nonWorkableInventory = success.data.nonWorkableInventory;
      });
    this.retakeTypeLabels = [];
    this.retakeTypeDataPie = [];
    this.dataService.getListData('getRetakeTypes', { 'project_id': this.projectId, 'role': this.role, 'where': this.where })
      .subscribe((success) => {
        this.retakeTypes = success.data.retakeTypes;
        this.totalRetake = success.data.totalRetake;
        this.retakeTypeDataPie = success.data.retakeTypeChartData;
        this.retakeTypeLabels = success.data.retakeTypeLabels;
        this.isRetakeType = true;
        this.retakeTypeColors[0]['backgroundColor'] = success.data.retakeTypeColors;
      }, (rejected) => {

      });

    this.dataService.getListData('dashboardgetProjectPhaseDepts', { 'project_id': this.projectId, 'phase_id': this.selectedPhaseId, 'tab': 'dashboard' })
      .subscribe((success) => {
        this.departments = success.data;
      }, (rejected) => {

      });
  }

  ngOnChanges() { }

  search(query, column, type, isProject) {
    if (column == 'phase') {
      if (this.phaseList.length > 0) {
        this.phaseList = [...this.phaseList]
      } else {
        this.getPhaseByType(this.projectId, '', false);
      }
    } else if (column == 'department') {
      this.getDepartments(this.projectId, query, type);
    } else if (column == 'episode') {
      this.getEpisodes(this.projectId, query, type);
    } else if (column == 'retake-types') {
      this.getRetakeTypeList(query);
    }
  }

  getProjectData() {
    this.dataService.getListData('getProjectData', { 'project_id': this.projectId })
      .subscribe((success) => {
        this.projects = success.data.projects;
        this.projects.total_frame = success.data.total_frame;
        this.projects.total_seconds = success.data.total_seconds.toFixed(2);
        this.projects.total_bids = success.data.total_bids;
        localStorage.setItem('localStorageProjectData', JSON.stringify(success.data.projects));
        localStorage.setItem('episodicSeries', this.projects.episodic_series);
        localStorage.setItem('isExternalReview', this.projects.is_external_review);
        this.dataService.setProjectData(this.projects);
      }, (rejected) => {

      });
  }

  displayDetailView(val) {
    this.is_open_detail = val;
  }

  getProjectProgress(type) {
    this.dataService.getListData('getProjectProgress', { 'project_id': this.projectId, 'role': this.role, 'type': type })
      .subscribe((success) => {
        //get Tasks Progress
        if (type == 'taskProgress') {
          this.taskProgress = success.data.taskProgress;
          //this.taskProgressCount = success.data.taskProgressCount;
          //this.approvedCount = success.data.approvedCount;
          this.projectCompletion = false;
          if (success.data.taskProgress.progress.approvedCount[0] == 0) {
            // if (success.data.taskProgress.progress.approvedCount[1] == 0) {
            success.data.taskProgress.progress.approvedCount[1] = 100;
            // }
          }
          this.taskProgressData = success.data.taskProgress.progress.approvedCount;
        } else if (type == 'bidDaysProgress') {
          this.projectEfficiency = success.data.bidDaysProgress.projectEfficiency;

          //get Bid days progress
          this.bidDays = success.data.bidDaysProgress;
          this.actualVsBidDays = false
        } else if (type == 'activeDataAssetShot') {
          //get Active Data
          this.activeData = success.data.activeData;
          //get Assets and Shots progress
          this.assetShot = success.data.assetShot;
          this.teamMeberAndShotAsset = false;
        }
        this.cd.markForCheck();
      }, (rejected) => {
        this.projectCompletion = false;
        this.actualVsBidDays = false;
        this.teamMeberAndShotAsset = false;
      });
  }

  getTasksStatus(departmentId, episodeId, type) {
    this.where.department_id = departmentId;
    this.where.episodeId = episodeId;
    this.dataService.getListData('getTasksStatus', { 'project_id': this.projectId, 'role': this.role, 'type': type, 'where': this.where })
      .subscribe((success) => {
        this.tasksStatus = success.data;
        this.taskStatusDataPie = success.data.statusChartData;
        this.taskStatusLabels = success.data.statusNames;
        this.role = success.data.role;
        this.roleType = success.data.roleType;
        type == 2 ? this.isTaskStatus = true : this.isTaskStatus = false;
        this.taskStatusColors[0]['backgroundColor'] = success.data.chartColor;
        this.nonWorkableInventory = success.data.nonWorkableInventory;
      }, (rejected) => {

      });
  }

  getDepartmentTasks() {
    this.dataService.getListData('getDepartmentTasks', { 'project_id': this.projectId })
      .subscribe((success) => {
        this.departmentTasks = success.data;
      }, (rejected) => {

      });
  }

  getPhaseByType(project_id, query, isCalledFirstTime = false) {
    let query1 = query == '' ? '' : query['query'];
    this.dataService.getListData('dashboardgetProjectPhases', { 'project_id': project_id, 'query': query1 })
      .subscribe((success) => {
        this.phaseList = success.data;
        if (isCalledFirstTime) {
          this.dataService.getListData('project' + '/' + 'project_phase' + '/preferences', { project_id: this.projectId })
            .subscribe((success) => {
              if (success.status == 'success' && success.data.length != 0) {
                this.onSelect('phase', success.data.phase_id);
                this.data.phase_id = this.phaseList.find(obj => obj.id == success.data.phase_id);
              } else {
                this.onSelect('phase', this.phaseList[0]['id']);
                this.data.phase_id = this.phaseList[0];
              }
            }, (rejected) => {
            });

        }
      }, (rejected) => {

      });
  }



  selectedPhaseId: any;
  onSelect(column, event, type = "") {
    let event1 = (event.id) ? event.id : event;
    this.selectedPhaseId = event1;
    if (column == 'phase') {
      this.storePhaseid({ phase_id: event1 });
      this.dataService.getListData('dashboardgetProjectPhaseDepts', { 'project_id': this.projectId, 'phase_id': event1, 'tab': 'dashboard' })
        .subscribe((success) => {
          this.departments = success.data;
        }, (rejected) => {

        });
    } else if ((column == 'department' || column == 'episode') && type != 'retake-types') {
      const shotStatusFilter = {
        department_id: this.data.department_id,
        episode_id: this.data.episode_id,
      }
      this.storeProjectStatus(shotStatusFilter)
      this.getTasksStatus(this.data.department_id, this.data.episode_id, 2);
      // Store the filter values in local storage
    } else if (type == 'retake-types') {
      const retakeStatus = {
        department_id: this.data.rt_department_id,
        episode_id: this.data.rt_episode_id,
        retake_type_id: this.data.retake_type_id
      }
      this.storeRetakeStatus(retakeStatus)
      this.getRetakeTypes(this.data.rt_department_id, this.data.rt_episode_id, this.data.retake_type_id);
    }

    //dashboardgetProjectPhaseDepts
  }

  /** Store Task status filter */
  storeProjectStatus(value: any) {
    this.dataService.updateData('project' + '/' + 'project_status' + '/preferences', { 'value': value, project_id: this.projectId })
      .subscribe((success) => {
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  /** Store Retake status filter */
  storeRetakeStatus(value: any) {
    this.dataService.updateData('project' + '/' + 'project_status_retake' + '/preferences', { 'value': value, project_id: this.projectId })
      .subscribe((success) => {
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  /** Store Phase Id */
  storePhaseid(value) {
    this.dataService.updateData('project' + '/' + 'project_phase' + '/preferences', { 'value': value, project_id: this.projectId })
      .subscribe((success) => {
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  getDepartments(project_id, query, type) {
    this.dataService.getListData('dashboardgetDepartments', { 'project_id': project_id, 'query': query['query'] })
      .subscribe((success) => {
        if (type != 'retake-types') {
          this.deptList = success.data;
          this.deptList.unshift({ id: 0, department: 'All', dtt_id: null });
        } else {
          this.rtdeptList = success.data;
          this.rtdeptList.unshift({ id: 0, department: 'All', dtt_id: null });
        }
      }, (rejected) => {

      });
  }

  getEpisodes(project_id, query, type) {
    this.dataService.getListData('episodesItems', { project_id: project_id, 'fromCreateTask': true, 'query': query['query'] })
      .subscribe((success) => {
        if (type != 'retake-types') {
          this.episodesList = success.data;
          this.episodesList.unshift({ id: 0, name: 'All' });
        } else {
          this.rtEpisodeList = success.data;
          this.rtEpisodeList.unshift({ id: 0, name: 'All' });
        }
      }, (rejected) => {

      });
  }

  getRetakeTypes(departmentId, episodeId, retakeTypeId) {
    this.where.rt_department_id = departmentId;
    this.where.rt_episode_id = episodeId;
    this.where.retake_type_id = retakeTypeId;
    this.dataService.getListData('getRetakeTypes', { 'project_id': this.projectId, 'role': this.role, 'where': this.where })
      .subscribe((success) => {
        this.retakeTypes = success.data.retakeTypes;
        this.totalRetake = success.data.totalRetake;
        this.retakeTypeDataPie = success.data.retakeTypeChartData;
        this.retakeTypeLabels = success.data.retakeTypeLabels;
        this.isRetakeType = true;
        this.isRetakeStatus = false;
        this.retakeTypeColors[0]['backgroundColor'] = success.data.retakeTypeColors;
      }, (rejected) => {

      });
  }

  getRetakeTypeList(query) {
    this.dataService.getListData('getRetakeTypeList', { 'query': query['query'] })
      .subscribe((success) => {
        this.retakeTypeList = success.data;
        this.retakeTypeList.unshift({ id: 'all', item_name: 'All' });
      }, (rejected) => {

      });
  }

  /** Mouse hover event */
  mouseHover() {
    // $(".spannn").mouseenter(function () {
    //   $(".menu-list").toggleClass("d-block");
    // });
  }

  /** Mouse out event */
  mouseOutEvent() {
    $(".breadcrumb-title").mouseleave(function () {
      $(".menu-list").removeClass("d-block");
    });
  }

  setProjectId(id) {
    // this.getProjectsById(id);
    this.shotStatusReport(id);
    this.assetStatusReport(id);
    this.currentProject = id;
    this.parentProjectId = id;
    this.isClassVisible = !this.isClassVisible;
    document.getElementById('collapseOne').classList.remove('in');
    document.getElementById('collapseTwo').classList.remove('in');
    this.shotStatusReport(id);
  }

  getProjectsById(id) {
    this.projectId = id;
    this.dataService.getDataById('getProjectById/' + id)
      .subscribe((success) => {
        this.projectData = success.data;
        this.dataService.setProjectData(this.projectData);
        localStorage.setItem('localStorageProjectData', JSON.stringify(success.data));
        localStorage.setItem('episodicSeries', this.projects.episodic_series);
        localStorage.setItem('isExternalReview', this.projects.is_external_review);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.message);
          this.router.navigate(['/dashboard']);
        }
      });

    if (this.role != 'admin') {
      this.commonJs.getProjectPermission(id);
    }
    this.getProjectRole(id);
  }


  getProjectRole(id) {
    this.dataService.getOtherData('getProjectRole', { 'project_id': id })
      .subscribe((success) => {
        if (localStorage.getItem('projectRoles') != null) {
          this.projectroles = JSON.parse(localStorage.getItem('projectRoles'));
        } else {
          this.projectroles = {};
        }
        this.projectroles['project_' + id] = success.role;
        localStorage.setItem('projectRoles', JSON.stringify(this.projectroles));

        if (localStorage.getItem('projectRoleType') != null) {
          this.projectrolestype = JSON.parse(localStorage.getItem('projectRoleType'));
        } else {
          this.projectrolestype = {};
        }
        this.projectrolestype['project_' + id] = success.role_type;
        localStorage.setItem('projectRoleType', JSON.stringify(this.projectrolestype));

      }, (error) => {
      });
  }

  /** Get shot status report */
  shotStatusReport(id: any) {
    this.dataService.shotStatusReport(id)
      .subscribe(result => {
        if (result.status == 'success') {
          this.barChartLabels.length = this.barChartLabelsAssets.length = 0;
          this.barChartLabels.push('Waiting to Start ' + '(' + result.data.shot.waitingToGetStarted.count + ')');
          this.barChartLabels.push('Done ' + '(' + result.data.shot.done.count + ')');
          this.barChartLabels.push('In Progress ' + '(' + result.data.shot.inProgress.count + ')');
          this.barChartLabels.push('Other ' + '(' + result.data.shot.NA.count + ')');
          /** Shots */
          const shotData: any = { barThickness: 18 };
          shotData.data = [result.data.shot.waitingToGetStarted.percentage, result.data.shot.done.percentage, result.data.shot.inProgress.percentage, result.data.shot.NA.percentage, 100];
          shotData.options = { responsive: true };
          shotData.label = '';
          shotData.stack = '';
          shotData.borderSkipped = false;
          shotData.fill = true;
          shotData.borderRadius = 50;
          shotData.borderWidth = 2;
          shotData.hoverBackgroundColor = ['rgb(169, 169, 169)', 'rgb(43, 152, 43)', 'rgb(26, 102, 239)', '#F26927'];
          this.barChartData = [shotData];
          this.shotStatusGraph = false;
          this.cd.markForCheck();
        }
      },
        error => {
          this.barChartLabels = [];
          this.shotStatusGraph = false;
        },
        () => {
          this.shotStatusGraph = false;
        });
  }

  /** Get Asset shot status */
  assetStatusReport(id: any) {
    this.dataService.assetStatusReport(id)
      .subscribe(result => {
        // Handle result
        //console.log(result)
        if (result.status == 'success') {
          this.barChartLabelsAssets.length = this.barChartLabelsAssets.length = 0;
          this.barChartLabelsAssets.push('Waiting to Start ' + '(' + result.data.asset.waitingToGetStarted.count + ')');
          this.barChartLabelsAssets.push('Done ' + '(' + result.data.asset.done.count + ')');
          this.barChartLabelsAssets.push('In Progress ' + '(' + result.data.asset.inProgress.count + ')');
          this.barChartLabelsAssets.push('Other ' + '(' + result.data.asset.NA.count + ')');
          /** Assets */
          const assets: any = { borderRadius: 50, barThickness: 18 };
          assets.data = [result.data.asset.waitingToGetStarted.percentage, result.data.asset.done.percentage, result.data.asset.inProgress.percentage, result.data.asset.NA.percentage, 100];
          assets.label = '';
          assets.stack = '';
          assets.borderSkipped = false;
          assets.hoverBackgroundColor = ['rgb(169, 169, 169)', 'rgb(43, 152, 43)', 'rgb(26, 102, 239)', '#F26927'];
          this.barChartDataAssets = [assets];
          this.assetStatusGraph = false;
          this.cd.markForCheck();
        }
      },
        error => {
          this.barChartLabelsAssets = [];
          this.assetStatusGraph = false;
        },
        () => {
          this.assetStatusGraph = false;
        });
  }

  // onWindowScroll(e) {
  //   //console.log(e)
  //   if (window.pageYOffset > 550) {
  //     let element = document.getElementById('dash-navbar');
  //     element.classList.add('sticky');
  //   } else {
  //     let element = document.getElementById('dash-navbar');
  //     element.classList.remove('sticky');
  //   }
  // }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    //console.log(offset);
  }

  // On hover upon chart
  chartHovered(event) {
    //console.log(event);
  }

  // On click upon chart
  chartClicked(event) {
    //console.log(event);
  }

  // To toggle class when tab is expanded
  statusTabToggled(event) {
    this.isTaskStatus = false;
    // if (document.getElementById(event).classList.contains('collapsed')) {
    this.dataService.getListData('project' + '/' + 'project_status' + '/preferences', { project_id: this.projectId })
      .subscribe((success) => {
        if (success.status == 'success' && success.data.length != 0) {
          this.data.department_id = success.data.department_id;
          this.data.episode_id = success.data.episode_id;
          this.isTaskStatus = true;
          this.getTasksStatus(this.data.department_id, this.data.episode_id, 2);
        } else {
          this.data.department_id = { id: 0, department: 'All', dtt_id: null };
          this.data.episode_id = { id: 0, name: 'All' };
          this.isTaskStatus = true;
          this.getTasksStatus(this.data.department_id, this.data.episode_id, 2);
        }
      }, (rejected) => {
      });
    // document.getElementById(event).classList.remove('collapsed');
    // }
    // else {
    //   document.getElementById(event).classList.add('collapsed');
    // }
  }

  /** Toggle retake status */
  retakeStatusToggled(event) {
    this.isRetakeStatus = true;
    // if (document.getElementById(event).classList.contains('collapsed')) {
    this.dataService.getListData('project' + '/' + 'project_status_retake' + '/preferences', { project_id: this.projectId })
      .subscribe((success) => {
        if (success.status == 'success' && success.data.length != 0) {
          this.data.rt_department_id = success.data.department_id;
          this.data.rt_episode_id = success.data.episode_id;
          this.data.retake_type_id = success.data.retake_type_id;
          this.isRetakeStatus = false;
          this.getRetakeTypes(success.data.department_id, success.data.episode_id, success.data.retake_type_id);
        } else {
          this.isRetakeStatus = false;
          this.getRetakeTypes(0, 0, 'all');
        }
      }, (rejected) => {
      });
    //   document.getElementById(event).classList.remove('collapsed');
    // }
    // else {
    //   document.getElementById(event).classList.add('collapsed');
    // }
  }

  // To open dropdown of projects from header
  openDrodown($event, showCondition) {
    $event.stopPropagation();
    this.isClassVisible = showCondition;
  }

  // On click outside nav-menu
  onClickAnywhereElse() {
    this.isClassVisible = false;
  }

}
