export function columns(key: string, cellTemplate: any = {}) {
    if (key === 'versionTable') {
        const versionTable = [
            {
                prop: 'version',
                name: 'Version',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
                // headerTemplate: cellTemplate.version,
                cellTemplate: cellTemplate.revisionVersion,
                headerTemplate: cellTemplate.revisionVersionHeader
            },
            {
                prop: 'name',
                name: 'Assigned To',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            },
            {
                prop: 'start_date',
                name: 'Planned Start Time',
                sortable: false,
                draggable: false,
                minWidth: 160,
                maxWidth: 160,
                width: 160,
                canAutoResize: true,
                cellClass: 'hideoverflow',
                cellTemplate: cellTemplate.plannedStartTime,
            },
            {
                prop: 'end_date',
                name: 'Planned End Time',
                sortable: false,
                draggable: false,
                minWidth: 160,
                maxWidth: 160,
                width: 160,
                canAutoResize: true,
                cellClass: 'hideoverflow',
                cellTemplate: cellTemplate.plannedEndTime,
            },
            {
                prop: 'bid_days',
                name: 'Bid Days',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            },
            {
                prop: 'spend_time',
                name: 'W/ Days',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            },
            {
                prop: 'total_duration',
                name: 'Spent Time',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
                cellTemplate: cellTemplate.status
            },
            {
                prop: 'reviewed_by',
                name: 'Reviewer',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            },
        ]
        return versionTable;
    } else if (key === 'bidTable') {
        const bidTable = [
            {
                prop: 'department',
                name: 'Department',
                sortable: false,
                draggable: false,
                minWidth: 208,
                maxWidth: 208,
                width: 208,
                canAutoResize: true,
            },
            {
                prop: 'bid_days',
                name: 'Bid Days',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.bidDays,
            },
            {
                prop: 'actualSpentDays',
                name: 'Actual Days',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.actualdays,
            },
            {
                prop: 'variance',
                name: 'Variance',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
            },
        ]
        return bidTable;
    } else if (key === 'taskInfo') {
        const taskInfo = [
            {
                prop: 'dept_name',
                name: 'Department',
                sortable: false,
                draggable: false,
                minWidth: 205,
                maxWidth: 205,
                width: 205,
                canAutoResize: true,
                cellTemplate: cellTemplate.department
            },
            {
                prop: 'revision',
                name: 'Version',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
                cellTemplate: cellTemplate.revisionVersion,
                headerTemplate: cellTemplate.revisionVersionHeader
            },
            {
                prop: 'assigned_to_name',
                name: 'Assigned To',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'end_date',
                name: '	Planned End Time',
                sortable: false,
                draggable: false,
                minWidth: 190,
                maxWidth: 190,
                width: 190,
                canAutoResize: true,
                cellTemplate: cellTemplate.plannedEndTime,
            },
            {
                // prop: 'actual_start_date',
                prop: 'start_date',
                name: '	Actual Start Time',
                sortable: false,
                draggable: false,
                minWidth: 190,
                maxWidth: 190,
                width: 190,
                canAutoResize: true,
                cellTemplate: cellTemplate.actualStartTime,
            },
            {
                prop: 'status',
                name: '	Status',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
                cellTemplate: cellTemplate.status
            },
        ]
        return taskInfo;
    } else if (key === 'shotLinked') {
        const shotLinked = [
            {
                prop: '',
                name: 'Image',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'name',
                name: 'Name',
                sortable: false,
                draggable: false,
                minWidth: 200,
                maxWidth: 200,
                width: 200,
                canAutoResize: true,
            },
            {
                prop: 'assetCode',
                name: 'Code',
                sortable: false,
                draggable: false,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'type',
                name: '	Type - Sub Type',
                sortable: false,
                draggable: false,
                minWidth: 158,
                maxWidth: 158,
                width: 158,
                canAutoResize: true,
            }
        ]
        return shotLinked;
    } else if (key === 'assetLinked') {
        const assetLinked = [
            {
                prop: '',
                name: 'Image',
                sortable: false,
                draggable: false,
                minWidth: 100,
                maxWidth: 100,
                width: 100,
                canAutoResize: true,
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'shot_number',
                name: 'Name',
                sortable: false,
                draggable: false,
                minWidth: 160,
                maxWidth: 160,
                width: 160,
                canAutoResize: true,
            },
            {
                prop: 'sequence_name',
                name: 'Sequence',
                sortable: false,
                draggable: false,
                minWidth: 248,
                maxWidth: 248,
                width: 248,
                canAutoResize: true,
            },
            {
                prop: 'no_of_frames',
                name: 'No of frames',
                sortable: false,
                draggable: false,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
            }
        ]
        return assetLinked;
    } else if (key === 'user-task-list') {
        const userTaskList = [
            {
                prop: 'info',
                name: 'Task Info',
                sortable: false,
                draggable: false,
                minWidth: 60,
                maxWidth: 280,
                width: 280,
                canAutoResize: true,
                resizable: true,
                cellTemplate: cellTemplate.info,
            },
            {
                prop: 'department',
                name: 'Departments',
                sortable: false,
                draggable: false,
                minWidth: 210,
                maxWidth: 210,
                width: 210,
                canAutoResize: true,
                cellTemplate: cellTemplate.department,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: false,
                minWidth: 210,
                maxWidth: 210,
                width: 210,
                canAutoResize: true,
                cellTemplate: cellTemplate.status,
            },
            {
                prop: 'start_date',
                name: 'Start Date',
                sortable: false,
                draggable: false,
                minWidth: 210,
                maxWidth: 210,
                width: 210,
                canAutoResize: true,
                cellTemplate: cellTemplate.startDate,
            },
            {
                prop: 'end_date',
                name: 'End Date',
                sortable: false,
                draggable: false,
                minWidth: 210,
                maxWidth: 210,
                width: 210,
                canAutoResize: true,
                cellTemplate: cellTemplate.endDate,
            },
        ]
        return userTaskList;
    }
}
