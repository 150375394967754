import { Component, HostListener, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';


@Component({
  selector: 'app-thumbnail-modal',
  templateUrl: './thumbnail-modal.component.html',
  styleUrls: ['./thumbnail-modal.component.css']
})
export class ThumbnailModalComponent implements OnInit {

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // console.log(event);
    if(event.key=='Escape'){
      this.closeImageModal();
    }
  }

  constructor(
    public modalService:BsModalService,
    public thumbnailModalService:ThumbnailModalServiceService) {

  }

  ngOnInit() {
  }

  closeImageModal(){
    this.thumbnailModalService.closeThumbnailModal();
  }


}
