import { Injectable } from '@angular/core';
import { DataService } from '../services/data.service';
import { AppConfigs } from './app-configs';
declare var $: any;
declare var jQuery: any;
import * as _ from "lodash";
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GridService } from '../services/grid.service';
import * as moment from 'moment-timezone'


@Injectable()
export class CommonJs {
	//@Output() setThemeStyleApp = new EventEmitter();
	//public  serverUrl = 'http://180.211.120.106/wtshub/pline-api/api/';
	//public  serverUrl = this.appConfigs.serverUrl('serverUrl');
	//public  uploadUrl = 'http://180.211.120.106/wtshub/pline-api/public/uploads/';
	public serverUrl = this.appConfigs.serverUrl;
	//public  uploadUrl = this.appConfigs.uploadUrl;
	public uploadUrl = this.appConfigs.uploadUrl;
	permissions = [];
	accessReturn: boolean = false;

	projectrole = {};
	projectrolestype = {};

	projectPer = {};
	initialState: any = '';

	private getAllSettingResponse$: BehaviorSubject<string>;
	private timer;

  public phasesExist:any = {};

	constructor(
		private dataService: DataService,
		public appConfigs: AppConfigs,
		public gridService: GridService
	) {
		this.getAllSettingResponse$ = new BehaviorSubject(this.initialState);

	}


	// To change the global time-zone
	changeGlobalTimeZone() {
		moment.tz.setDefault(JSON.parse(localStorage.getItem('settings')).timezone.split('=>')[0]);
	}


	initiateJS() {
		$.AdminBSB.browser.activate();
		//$.AdminBSB.leftSideBar.activate();
		//$.AdminBSB.rightSideBar.activate();
		$.AdminBSB.navbar.activate();
		$.AdminBSB.input.activate();
		$.AdminBSB.search.activate();
		/*setTimeout(function () { $('.page-loader-wrapper').fadeOut(); }, 50); */

		//$.AdminBSB.scroll.activate();
	}

	getUploadUrl() {
		return this.appConfigs.getUploadUrl();
	}

	getSetting() {
		this.dataService.getSetting({ type: 'date_format' })
			.subscribe((success) => {
				localStorage.setItem('date_format', success);
			}, (error) => {
			});
	}

	getAllSettings(calledFrom = true) {
		this.dataService.getOtherData('getAllSettings', { calledFrom: calledFrom })
			.subscribe((success) => {
				localStorage.setItem('settings', JSON.stringify(success.settings));

        // To change the global time-zone
        this.changeGlobalTimeZone();

				this.gridService.taskPlayPuase.emit(true)
				// console.log(success.settings.timezone.split("=>")[0])
				// let d: any = new Date(new Date().toLocaleString("en-US", { timeZone: success.settings.timezone })); // timezone ex: Asia/Jerusalem
				let d: any = new Date(new Date().toLocaleString("en-US", { timeZone: success.settings.timezone.split("=>")[0] })); // timezone ex: Asia/Jerusalem
				if (environment.prodType == 'offline' && success.settings.studio_offline_dist == 1) {
					this.dataService.autoDistExtract('autoDistExtrate').subscribe((auto: any) => {
						setTimeout(() => {
							location.reload();
						}, 5000);
					})
				}
				this.getAllSettingResponse$.next(success.settings);
			}, (error) => {
			});

      // For unhiding the headers on any page changes
      this.dataService.fullScreenView.next(false);
	}

	get getAllSettingResponse(): string {
		return this.getAllSettingResponse$.getValue();
	}

	getConfig() {
		this.dataService.getOtherData('getConfig', '')
			.subscribe((success) => {
				localStorage.setItem('config', JSON.stringify(success));
			}, (error) => {
			});
	}

	getPermissions() {
		this.dataService.getOtherData('getPermissions', '')
			.subscribe((success) => {
				localStorage.setItem('permissions', JSON.stringify(success));
				this.dataService.permissionChanged("");
			}, (error) => {
			});
	}

	getProjectPermission(projectId) {
		this.dataService.getOtherData('getPermissions', { 'project_id': projectId })
			.subscribe((success) => {
				if (localStorage.getItem('projectPermissions') != null) {
					this.projectPer = JSON.parse(localStorage.getItem('projectPermissions'));
				} else {
					this.projectPer = {};
				}
				this.projectPer['project_' + projectId] = success;
				localStorage.setItem('projectPermissions', JSON.stringify(this.projectPer));

			}, (error) => {
			});
	}

	getInitValue() {
		this.dataService.getOtherData('getInitValue', '')
			.subscribe((success) => {
				localStorage.setItem('permissions', JSON.stringify(success.permissions));
				localStorage.setItem('config', JSON.stringify(success.config));
				localStorage.setItem('settings', JSON.stringify(success.settings));

			}, (error) => {
			});
	}

	getProjectRole(projectId) {
		return new Promise((resolve, reject) => {
			this.dataService.getOtherData('getProjectRole', { 'project_id': projectId })
				.subscribe((success) => {
					if (localStorage.getItem('projectRoles') != null) {
						this.projectrole = JSON.parse(localStorage.getItem('projectRoles'));
					} else {
						this.projectrole = {};
					}
					this.projectrole['project_' + projectId] = success.role;
					localStorage.setItem('projectRoles', JSON.stringify(this.projectrole));

					if (localStorage.getItem('projectRoleType') != null) {
						this.projectrolestype = JSON.parse(localStorage.getItem('projectRoleType'));
					} else {
						this.projectrolestype = {};
					}
					this.projectrolestype['project_' + projectId] = success.role_type;
					localStorage.setItem('projectRoleType', JSON.stringify(this.projectrolestype));
					resolve(true)
				}, (error) => {
					reject();
				});
		});
	}

	checkAccess(entity, access, baipass, checkProject = '', isStatic = false) {
		let checkMore = true;
		if (baipass != undefined && Object.keys(baipass).length > 0) {
			let checkAcc = false;
			_.forEach(baipass, function (value) {
				if (value == localStorage.getItem('role')) {
					checkAcc = true;
				}
			});

			if (!checkAcc) {
				checkMore = true;
			} else {
				checkMore = false;
			}
		}

		if (checkMore) {
			this.accessReturn = false;
			let that = this;

			if (checkProject != '') {
				if (localStorage.getItem('projectPermissions') != undefined) {
					let projectPer = JSON.parse(localStorage.getItem('projectPermissions'));
					this.permissions = (projectPer['project_' + checkProject] != undefined) ? projectPer['project_' + checkProject] : [];
				} else {
					this.permissions = [];
				}
			} else {


				if (localStorage.getItem('permissions') != undefined) {
					this.permissions = JSON.parse(localStorage.getItem('permissions'));
				}
			}
			_.forEach(this.permissions, function (value) {
				if (value.slug == entity) {
					if (value[access + '_data']) {
						that.accessReturn = true;
					}
				}
			});

			return this.accessReturn;
		} else {
			return true;
		}
	}

	getSettingValue(key) {
		let settingsValues = JSON.parse(localStorage.getItem('settings'));
		if (settingsValues != null) {
			if (settingsValues[key]) {
				return settingsValues[key];
			} else {
				return '';
			}

		} else {
			return '';
		}
	}

	getUserName(key) {
		let userValues = JSON.parse(localStorage.getItem('user'));
		if (userValues[key]) {
			if (key == 'image') {
				return this.uploadUrl + 'users/' + userValues['image'];
			} else {
				return userValues[key];
			}
		} else {
			return '';
		}
	}

	getUserImage() {
		let userValues = JSON.parse(localStorage.getItem('user'));
		if (userValues['image']) {
			return this.getUploadUrl() + 'users/' + userValues['image'];
		}
		return "";
	}

	getLocalStorage(key) {
		return localStorage.getItem(key);
	}

	getProjectLocalStorage(key, project_id) {
		let projectData = JSON.parse(localStorage.getItem(key));
		if (projectData != null && projectData.hasOwnProperty('project_' + project_id)) {
			return projectData['project_' + project_id];
		} else {
			return '';
		}
	}

	getClientCodePrefix() {
		let client_code = localStorage.getItem('client_code');
		if (client_code == 'SA') {
			return 'superadmin';
		} else {
			// if(config == "1"){
			// 	return 'ac/' + client_code;
			// }else{
			// 	return 'ac/admin';
			// }
			return 'ac/' + client_code;
		}
	}

	getClientCode() {
		let client_code = localStorage.getItem('client_code');
		if (client_code == 'SA') {
			return 'superadmin';
		} else {
			// if(config == "1"){
			// 	return 'ac/' + client_code;
			// }else{
			// 	return 'ac/admin';
			// }
			return client_code;
		}
	}

	ngOnDestroy() {
		this.timer.unsubscribe();
	}
}
